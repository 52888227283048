import axios from "axios";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  // @ts-ignore
  const accessToken = nuxtApp.$auth.strategy.token.get();

  let api = axios.create({
    baseURL: config.public.API_URL,
    headers: {
      Authorization: accessToken,
      common: {
        'Accept': 'application/json, text/plain, */*',
      }
    }
  });

  return {
    provide: {
      api: api,
    },
  };
});
