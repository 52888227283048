import { default as _91token_93sJRmisV8IMMeta } from "/opt/atlassian/pipelines/agent/build/pages/accept-list/[token].vue?macro=true";
import { default as analyticsEtQTpq4zoPMeta } from "/opt/atlassian/pipelines/agent/build/pages/analytics.vue?macro=true";
import { default as authenticateNBSNBOJ2xeMeta } from "/opt/atlassian/pipelines/agent/build/pages/authenticate.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as _91listId_93Ix68McVcUGMeta } from "/opt/atlassian/pipelines/agent/build/pages/lists/[listId].vue?macro=true";
import { default as lists5eBB0WMDwjMeta } from "/opt/atlassian/pipelines/agent/build/pages/lists.vue?macro=true";
import { default as logged_45outaoMgzNcMYDMeta } from "/opt/atlassian/pipelines/agent/build/pages/logged-out.vue?macro=true";
import { default as logineaOYVOrhPDMeta } from "/opt/atlassian/pipelines/agent/build/pages/login.vue?macro=true";
import { default as notificationsfiw9pGySkpMeta } from "/opt/atlassian/pipelines/agent/build/pages/notifications.vue?macro=true";
import { default as profiley5vrxixVlAMeta } from "/opt/atlassian/pipelines/agent/build/pages/profile.vue?macro=true";
import { default as users252i3dULzCMeta } from "/opt/atlassian/pipelines/agent/build/pages/users.vue?macro=true";
import { default as listOBq0QWnYhDMeta } from "/opt/atlassian/pipelines/agent/build/pages/webshops/list.vue?macro=true";
import { default as _91id_93xc5yB77jBaMeta } from "/opt/atlassian/pipelines/agent/build/pages/webshops/profile/[id].vue?macro=true";
import { default as webshopsrxWhLAjtDUMeta } from "/opt/atlassian/pipelines/agent/build/pages/webshops.vue?macro=true";
export default [
  {
    name: _91token_93sJRmisV8IMMeta?.name ?? "accept-list-token",
    path: _91token_93sJRmisV8IMMeta?.path ?? "/accept-list/:token()",
    meta: _91token_93sJRmisV8IMMeta || {},
    alias: _91token_93sJRmisV8IMMeta?.alias || [],
    redirect: _91token_93sJRmisV8IMMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/accept-list/[token].vue").then(m => m.default || m)
  },
  {
    name: analyticsEtQTpq4zoPMeta?.name ?? "analytics",
    path: analyticsEtQTpq4zoPMeta?.path ?? "/analytics",
    meta: analyticsEtQTpq4zoPMeta || {},
    alias: analyticsEtQTpq4zoPMeta?.alias || [],
    redirect: analyticsEtQTpq4zoPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/analytics.vue").then(m => m.default || m)
  },
  {
    name: authenticateNBSNBOJ2xeMeta?.name ?? "authenticate",
    path: authenticateNBSNBOJ2xeMeta?.path ?? "/authenticate",
    meta: authenticateNBSNBOJ2xeMeta || {},
    alias: authenticateNBSNBOJ2xeMeta?.alias || [],
    redirect: authenticateNBSNBOJ2xeMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/authenticate.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: lists5eBB0WMDwjMeta?.name ?? "lists",
    path: lists5eBB0WMDwjMeta?.path ?? "/lists",
    children: [
  {
    name: _91listId_93Ix68McVcUGMeta?.name ?? "lists-listId",
    path: _91listId_93Ix68McVcUGMeta?.path ?? ":listId()",
    meta: _91listId_93Ix68McVcUGMeta || {},
    alias: _91listId_93Ix68McVcUGMeta?.alias || [],
    redirect: _91listId_93Ix68McVcUGMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/lists/[listId].vue").then(m => m.default || m)
  }
],
    meta: lists5eBB0WMDwjMeta || {},
    alias: lists5eBB0WMDwjMeta?.alias || [],
    redirect: lists5eBB0WMDwjMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/lists.vue").then(m => m.default || m)
  },
  {
    name: logged_45outaoMgzNcMYDMeta?.name ?? "logged-out",
    path: logged_45outaoMgzNcMYDMeta?.path ?? "/logged-out",
    meta: logged_45outaoMgzNcMYDMeta || {},
    alias: logged_45outaoMgzNcMYDMeta?.alias || [],
    redirect: logged_45outaoMgzNcMYDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/logged-out.vue").then(m => m.default || m)
  },
  {
    name: logineaOYVOrhPDMeta?.name ?? "login",
    path: logineaOYVOrhPDMeta?.path ?? "/login",
    meta: logineaOYVOrhPDMeta || {},
    alias: logineaOYVOrhPDMeta?.alias || [],
    redirect: logineaOYVOrhPDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/login.vue").then(m => m.default || m)
  },
  {
    name: notificationsfiw9pGySkpMeta?.name ?? "notifications",
    path: notificationsfiw9pGySkpMeta?.path ?? "/notifications",
    meta: notificationsfiw9pGySkpMeta || {},
    alias: notificationsfiw9pGySkpMeta?.alias || [],
    redirect: notificationsfiw9pGySkpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: profiley5vrxixVlAMeta?.name ?? "profile",
    path: profiley5vrxixVlAMeta?.path ?? "/profile",
    meta: profiley5vrxixVlAMeta || {},
    alias: profiley5vrxixVlAMeta?.alias || [],
    redirect: profiley5vrxixVlAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: users252i3dULzCMeta?.name ?? "users",
    path: users252i3dULzCMeta?.path ?? "/users",
    meta: users252i3dULzCMeta || {},
    alias: users252i3dULzCMeta?.alias || [],
    redirect: users252i3dULzCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/users.vue").then(m => m.default || m)
  },
  {
    name: webshopsrxWhLAjtDUMeta?.name ?? "webshops",
    path: webshopsrxWhLAjtDUMeta?.path ?? "/webshops",
    children: [
  {
    name: listOBq0QWnYhDMeta?.name ?? "webshops-list",
    path: listOBq0QWnYhDMeta?.path ?? "list",
    meta: listOBq0QWnYhDMeta || {},
    alias: listOBq0QWnYhDMeta?.alias || [],
    redirect: listOBq0QWnYhDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/webshops/list.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xc5yB77jBaMeta?.name ?? "webshops-profile-id",
    path: _91id_93xc5yB77jBaMeta?.path ?? "profile/:id()",
    meta: _91id_93xc5yB77jBaMeta || {},
    alias: _91id_93xc5yB77jBaMeta?.alias || [],
    redirect: _91id_93xc5yB77jBaMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/webshops/profile/[id].vue").then(m => m.default || m)
  }
],
    meta: webshopsrxWhLAjtDUMeta || {},
    alias: webshopsrxWhLAjtDUMeta?.alias || [],
    redirect: webshopsrxWhLAjtDUMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/webshops.vue").then(m => m.default || m)
  }
]