import plugin_vue3_A0OWXRrUgq from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OcT8LIvbNC from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
import http_plugin_SfyNGJvhOm from "/opt/atlassian/pipelines/agent/build/.nuxt/http.plugin.mjs";
import auth_plugin_6DDHMgc34l from "/opt/atlassian/pipelines/agent/build/.nuxt/auth.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apexcharts_VxzzcHGhhi from "/opt/atlassian/pipelines/agent/build/plugins/apexcharts.ts";
import axios_QMFgzss1s4 from "/opt/atlassian/pipelines/agent/build/plugins/axios.ts";
import countryflag_7fr844E1ty from "/opt/atlassian/pipelines/agent/build/plugins/countryflag.ts";
import numberformat_lNWbxkw80w from "/opt/atlassian/pipelines/agent/build/plugins/numberformat.ts";
import openlayer_kk0ci2cBuH from "/opt/atlassian/pipelines/agent/build/plugins/openlayer.ts";
import scrollbar_ebQvq1qiBz from "/opt/atlassian/pipelines/agent/build/plugins/scrollbar.ts";
import slicksort_TNeq8NuWOK from "/opt/atlassian/pipelines/agent/build/plugins/slicksort.ts";
import toast_ysMjUcU7eJ from "/opt/atlassian/pipelines/agent/build/plugins/toast.ts";
import vuetify_7h9QAQEssH from "/opt/atlassian/pipelines/agent/build/plugins/vuetify.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_OcT8LIvbNC,
  http_plugin_SfyNGJvhOm,
  auth_plugin_6DDHMgc34l,
  chunk_reload_client_UciE0i6zes,
  apexcharts_VxzzcHGhhi,
  axios_QMFgzss1s4,
  countryflag_7fr844E1ty,
  numberformat_lNWbxkw80w,
  openlayer_kk0ci2cBuH,
  scrollbar_ebQvq1qiBz,
  slicksort_TNeq8NuWOK,
  toast_ysMjUcU7eJ,
  vuetify_7h9QAQEssH
]