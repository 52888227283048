import { Auth, ExpiredAuthSessionError } from '#auth/runtime'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { defu } from 'defu';

// Active schemes
import { Oauth2Scheme } from '#auth/runtime'

export default defineNuxtPlugin(nuxtApp => {
    // Options
    const options = {
  "globalMiddleware": false,
  "enableMiddleware": true,
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "redirectStrategy": "storage",
  "routerStrategy": "router",
  "watchLoggedIn": true,
  "redirect": {
    "login": "/login",
    "logout": "/logged-out",
    "home": "/",
    "callback": "/authenticate"
  },
  "pinia": {
    "namespace": "auth",
    "persist": false
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/"
    }
  },
  "localStorage": {
    "prefix": "auth."
  },
  "sessionStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "auth0"
}

    // Create a new Auth instance
    const auth = new Auth(nuxtApp, options)

    // Register strategies
    auth.registerStrategy('auth0', new Oauth2Scheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['auth0'], {
  "domain": "dev-ho07cva4.eu.auth0.com",
  "clientId": "TNea4b8aVlxKj8WRP9fVvwqLLI3FLkAg",
  "audience": "https://auth.lit.io",
  "responseType": "token",
  "grantType": "authorization_code",
  "codeChallengeMethod": "S256",
  "resetOnError": true,
  "endpoints": {
    "authorization": "https://dev-ho07cva4.eu.auth0.com/authorize",
    "userInfo": "https://dev-ho07cva4.eu.auth0.com/userinfo",
    "token": "https://dev-ho07cva4.eu.auth0.com/oauth/token",
    "logout": "https://dev-ho07cva4.eu.auth0.com/v2/logout?returnTo=https://eip.tembi.io/logged-out"
  },
  "token": {
    "maxAge": 36000
  },
  "name": "auth0",
  "scope": [
    "openid",
    "profile",
    "email"
  ]
})))

    nuxtApp.provide('auth', auth)

    return auth.init().catch(error => {
        if (process.client) {
            // Don't console log expired auth session errors. This error is common, and expected to happen.
            // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
            // token. We don't want to log this as an error.
            if (error instanceof ExpiredAuthSessionError) {
                return
            }

            console.error('[ERROR] [AUTH]', error)
        }
    })
})