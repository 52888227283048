<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
useHead({
  script: [{ type: "text/javascript", id: "hs-script-loader", src: "//js-eu1.hs-scripts.com/139693738.js", async: true, defer: true }]
})
</script>

<style lang="scss"></style>