import { createInstance } from '@refactorjs/ofetch'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { defu } from "defu";

// Nuxt Options
const options = JSON.parse('{"baseURL":"http://localhost:3000/","browserBaseURL":"/","proxyHeaders":true,"proxyHeadersIgnore":["accept","connection","cf-connecting-ip","cf-ray","content-length","content-md5","content-type","host","if-modified-since","if-none-match","x-forwarded-host","x-forwarded-port","x-forwarded-proto"],"serverTimeout":10000,"clientTimeout":25000,"https":false,"retry":1,"headers":{"accept":"application/json, text/plain, */*"},"credentials":"omit","debug":false,"interceptorPlugin":false}')

const httpInstance = (options) => {
    // Create new Fetch instance
    const instance = createInstance(options)
    ''

    return instance
}

''

export default defineNuxtPlugin(ctx => {
    const runtimeConfig = useRuntimeConfig()

    // Use runtime config to configure options, with module options as the fallback
    const config = defu({}, runtimeConfig.http, runtimeConfig.public.http, options)

    // baseURL
    const baseURL = process.client ? config.browserBaseURL : config.baseURL

    // Defaults
    const defaults = {
        baseURL,
        retry: config.retry,
        timeout: process.server ? config.serverTimeout : config.clientTimeout,
        credentials: config.credentials,
        headers: config.headers,
    }

    if (config.proxyHeaders) {
        // Proxy SSR request headers
        if (process.server && ctx.ssrContext?.event?.node.req?.headers) {
            const reqHeaders = { ...ctx.ssrContext.event.node.req.headers }
            for (const h of config.proxyHeadersIgnore) {
                delete reqHeaders[h]
            }

            defaults.headers = { ...reqHeaders, ...defaults.headers }
        }
    }

    const http = httpInstance(defaults)

    if (!globalThis.$http) {
        globalThis.$http = http
    }

    return {
        provide: {
            http: http
        }
    }
})