import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { VDataTable, VDataTableServer, VDataTableVirtual } from 'vuetify/labs/VDataTable'

const tembiTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#6200EE',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    ssr: false,
    theme: {
      defaultTheme: 'tembiTheme',
      themes: {
        tembiTheme
      }
    },
    components: {
      VDataTable,
      VDataTableServer,
      VDataTableVirtual
    },
  })

  nuxtApp.vueApp.use(vuetify)
})